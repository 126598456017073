import React, { useState, useRef } from 'react';
import { Button, InputNumber } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import './Quantity.css';

interface QuantityProps {
  currentValue: number;
  handleMin: () => void;
  handlePlus: () => void;
  quantityMaxPerSale?: number;
  remainingQuantity?: number;
  minQuantity?: number;
  disabled?: boolean;
  handleChange?: (value: number) => void;
  isInput?: boolean;
  loading?: boolean;
  vertical?: boolean;
}

const Quantity = (props: QuantityProps) => {
  const [showInput, setShowInput] = useState<boolean>(false);

  let inputNumber = useRef(null);

  const getMaxQuantity = () => {
    //Default max quantity
    let maxQuantity = 500;

    if (props.quantityMaxPerSale && !props.remainingQuantity) {
      maxQuantity = props.quantityMaxPerSale;
    }

    if (props.remainingQuantity && !props.quantityMaxPerSale) {
      maxQuantity = props.remainingQuantity;
    }

    if (props.quantityMaxPerSale && props.remainingQuantity) {
      maxQuantity = Math.min(props.quantityMaxPerSale, props.remainingQuantity);
    }

    return maxQuantity;
  };

  const isBtnMinDisabled = () => {
    return props.currentValue === 0 || props.currentValue === props.minQuantity;
  };

  const isBtnMaxDisabled = () => {
    return props.currentValue === getMaxQuantity() || props.disabled;
  };

  const onChangeInput = (value: number) => {
    if (value && !props.loading) {
      props.handleChange(value);
    }
  };

  const handleShowValue = (show: boolean) => {
    if (props.isInput) {
      setShowInput(show);

      if (!show) {
        inputNumber.current.focus();
      }
    }
  };

  return (
    <div className={`quantity-container ${props.vertical && 'vertical'}`}>
      <Button
        className="quantity-button quantity-button-less"
        disabled={isBtnMinDisabled()}
        loading={props.loading}
        icon={<MinusOutlined />}
        onClick={props.handleMin}
      />
      {showInput ? (
        <InputNumber
          min={props.minQuantity}
          max={getMaxQuantity()}
          value={props.currentValue}
          defaultValue={props.currentValue}
          onChange={onChangeInput}
          onBlur={() => handleShowValue(false)}
          onPressEnter={() => handleShowValue(false)}
          ref={inputNumber}
          type={'number'}
        />
      ) : (
        <div className="quantityItem" onClick={() => handleShowValue(true)}>
          {props.currentValue}
        </div>
      )}

      <Button
        className="quantity-button quantity-button-more"
        disabled={isBtnMaxDisabled()}
        loading={props.loading}
        icon={<PlusOutlined />}
        onClick={props.handlePlus}
      />
    </div>
  );
};

export default Quantity;
